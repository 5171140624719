.popup {

    background: #3F3EA0;
    width: calc(100% - 30px);
    max-width: 964px;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: fixed;
    top: 110px;
    opacity: 0;
    pointer-events:none;
    transition: all 0.5s ease;
    z-index: 5; 
    border-top-left-radius: 10px;

    @include breakpoint(tablet) {
        top: 280px;
    }

    &.active {
        opacity: 1;
        pointer-events: unset;
    }

    .popup-carousel {
        height: 100%;
        overflow: initial;

        .MuiButtonBase-root {
            background: transparent;
            margin: 0;
        }

        svg {
            fill: #ffffffbf !important;
        }
    }

    .carousel-item {
        position: relative;
        height: 100%;
    }

    .title {
        position: absolute;
        top: -12px;
        left: 6px;
        background: #fff;
        height: 24px;
        border-radius: 12px;
        color: #FE6300;
        font-weight: 500;
        font-family: "Nitti";
        font-size: 18px;
        padding: 0 10px;
        display: block;
        z-index: 1;
        min-width: 52px;
        text-align: center;
    }

    .title--extra-padding {
        padding: 0 20px;
    }

    .desks {
        padding: 20px 40px;
        font-family: "Nitti";
        height: calc(100% - 40px);
        overflow: auto;
        isolation: isolate;

        @include breakpoint(tablet) {
            padding: 15px 40px 20px 40px;
        }

        hr {
            border: 0;
            border-top: 1px solid hsla(0 , 0%, 100%, .2);
        }

        .desks-content {
            @include breakpoint(tablet) {
                display: flex;
                justify-content: flex-start;
            }
        }
    }

    .desk {
        display: flex;

        @include breakpoint(tablet) {
            width: 50%;
            margin-top: 0;
        }

        .desk-no {
            background: #fff;
            height: 18px;
            min-width: 12px;
            border-radius: 12px;
            color: #FE6300;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            z-index: 10;
            padding: 0 5px;
            margin-right: 10px;

            @include breakpoint(tablet) {
                font-size: 16px;
                line-height: 19px;
                height: 20px;
                min-width: 18px;
            }
        }
    }

    .seat {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 16px;
        color: #fff;

        @include breakpoint(tablet) {
            font-size: 18px;
            line-height: 18px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        span {
            display: block;
            margin-bottom: 3px;
        }

        a {
            color: #fff;
            text-decoration: none;
            font-style: italic;
        }

        .seat-name {
            font-size: 18px;
            line-height: 18px;
            font-weight: 500;
            color: #fff;

            @include breakpoint(tablet) {
                font-size: 20px;
                line-height: 20px;
            }
        }
    }

    .popup-close {
        position: absolute;
        top: -11px;
        right: -11px;
        height: 24px;
        width: 24px;
        font-weight: 500;
        font-family: "Nitti";
        font-size: 18px;
        text-align: center;
        z-index: 10;
    }
}

.slick-next {
    right: 15px !important;
}

.slick-prev {
    left: 15px !important;
}
.slick-slider .slick-track, .slick-slider .slick-list,
.slick-slider {
    height: 100%;
}
.slick-list {
    overflow: unset !important;
    overflow-x: clip !important;
}

.slick-slide > div {
    height: 100%;
}