@import './assets/Scss/vendor/normalize';
@import './assets/Scss/variables';
@import './assets/Scss/mixins/breakpoints';
@import './assets/Scss/functional/fonts';
@import './assets/Scss/components/header';
@import './assets/Scss/components/banner';
@import './assets/Scss/components/intro';
@import './assets/Scss/components/map';
@import './assets/Scss/components/search';
@import './assets/Scss/components/popup';

html, body, #root {
  overflow: hidden;
  height: 100%;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  @media only screen and (min-width: 1025px) and (hover:hover) {
    max-width: unset;
  }

  @media only screen and (min-width: 1024px) and (orientation: landscape) and (pointer:coarse) {
    max-width: unset;
  }
}

.App {
  background: #010379;
  height: calc(100% - 20px);
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;

  @media only screen and (min-width: 1025px) and (hover:hover) {
    justify-content: center;
    align-items: center;
    background: #000074;
    height: 100%;
  }

  @media only screen and (orientation: landscape) and (pointer:coarse) {
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  @media only screen and (min-width: 1024px) and (orientation: landscape) and (pointer:coarse) {
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.container {
  width: calc(100% - 20px);
  margin: 0 auto;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all 0.75s ease;
}

main {
  @media only screen and (min-width: 1025px) and (hover:hover) {
    display: none;
  }

  @media only screen and (orientation: landscape) {
    display: none;
  }

  @media only screen and (min-width: 1024px) and (orientation: landscape) {
    display: none;
  }
}

.fallback {
  display: none;

  @media only screen and (min-width: 1025px) and (hover:hover) {
    display: block;
    width: 1000px;

    img {
      width: 100%;
    }
  }
}

.landscape-mobile {
  display: none;

  @media only screen and (max-width: 1023px) and (orientation: landscape) and (pointer:coarse) {
    display: block;
    height: 90%;

    img {
      height: 100%;
    }
  }

}

.landscape-tablet {
  display: none;

  @media only screen and (min-width: 1024px) and (orientation: landscape) and (pointer:coarse) {
    display: block;
    width: 90%;

    img {
      width: 100%;
    }
  }

}