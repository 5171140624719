@font-face {
  font-family: "Nitti";
  src: local("Nitti"),
    url("../../Fonts/NLNittiGrotesk-Regular.ttf") format("truetype"),
    url("../../Fonts/NLNittiGrotesk-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Nitti";
  src: local("Nitti"),
    url("../../Fonts/NLNittiGrotesk-Medium.ttf") format("truetype"),
    url("../../Fonts/NLNittiGrotesk-Medium.woff") format("woff");
  font-weight: 500;
}