// Breakpoints Mobile First
@mixin breakpoint($range) {
    @if $range == mobile-large {
        @media (min-width: $breakpoint-mobile-large)  { @content; }
    }
    @if $range == mobile-landscape {
        @media (min-width: $breakpoint-mobile-landscape)  { @content; }
    }
    @else if $range == tablet {
        @media (min-width: $breakpoint-tablet) { @content; }
    }
    @else if $range == tablet-landscape {
        @media (min-width: $breakpoint-tablet-landscape) { @content; }
    }
    @else if $range == desktop {
        @media (min-width: $breakpoint-desktop)  { @content; }
    }
    @else if $range == fallback {
        @media (min-width: 1025px)  { @content; }
    }
    @else if $range == desktop-large {
        @media (min-width: $breakpoint-desktop-large) { @content; }
    }
}