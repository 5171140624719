.banner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 50px;

  @include breakpoint(tablet) {
    height: 80px;
  }

  a {
    img {
      height: 50px;

      @include breakpoint(tablet) {
        height: 80px;
      }
    }
  }

  span {
    cursor: pointer;
  }
}