.map-helper {
  width: calc(100% - 20px);
  aspect-ratio: 780/465;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  bottom: -1000px;
  left: -1000px;
}

.map {
  padding-bottom: 12px;
  position: relative;
  transition: all 0.5s ease;

  img {
    width: 100%;
  }

  .map-large,
  .map-small {
    position: relative;
    padding: 0 10px;

    .zoom {
      width: 13%;
      height: 21%;
      position: absolute;
      top: 38%;
      left: 44%;
    }
    .tables {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .table {
        width: 8.5%;
        height: auto;
        aspect-ratio: 1/1;
        position: absolute;
        display: inline-block;
        cursor: pointer;
        img {
          position: absolute;
          top: 0;
          left: 0;
          margin: 0;

          &.hover {
            display: none;
          }

          &.horizontal {
            width: 100%;
            height: auto;
          }
          &.meeting {
            width: 330%;
            height: auto;
          }
          &.vertical {
            height: 95%;
            width: auto;
          }
        }

        &.active,
        &:hover {
          img {
            &.hover {
              display: block;
            }
            &.normal {
              display: none;
            }
          }
        }
      }
    }
  }

  .map-small {
    display: block;
    opacity: 1;
    pointer-events: initial;
    transition: all 0.5s ease;

    @include breakpoint(tablet) {
      display: none;
    }

    &.zoomedIn {
      display: block;
      opacity: 0;
      pointer-events: none;
      img {
        //width: 200%;

        @include breakpoint(tablet) {
          width: 100%;
        }
      }
      .tables {
        //width: 200%;

        @include breakpoint(tablet) {
          width: 100%;
        }
      }
    }
  }

  .map-large {
    display: block;
    width: 100%;
    padding: 0;
    overflow-x: auto;
    opacity: 0;
    transition: all 0.5s ease;
    position: absolute;
    bottom: -200px;
    left: 0;
    pointer-events: none;

    @include breakpoint(tablet) {
      display: block;
      opacity: 1;
      position: relative;
      pointer-events: initial;
      bottom: 0;
    }

    img {
      width: 180%;
      transition: all 0.5s ease;

      @include breakpoint(tablet) {
        width: 100%;
      }
    }
    .zoom {
      width: 100%;
      height: 33%;
      position: absolute;
      top: 40%;
      left: 40%;

      @include breakpoint(tablet) {
        width: 60%;
        left: 20%;
      }
    }

    .tables {
      width: 180%;
      transition: all 0.5s ease;

      @include breakpoint(tablet) {
        width: 100%;
      }


      .table {
        width: 14%;
        aspect-ratio: unset;
        display: block;

        &.table-vertical,
        &:has(> img.vertical) {
          min-height: 23%;
          width: 8%;
        }

        &.table-horizontal,
        &:has(> img.horizontal) {
          min-height: 10%;
        }

        img {
          transition: all 1s ease;
          &.vertical {
            height: 95%;
          }
          &.meeting {
            width: 200%;
          }
        }
      }
    }

    &.zoomedIn {
      display: block;
      opacity: 1;
      pointer-events: initial;
      bottom: 0;
      img {
        width: 180%;

        @include breakpoint(tablet) {
          width: 100%;
        }
      }
      .tables {
        width: 180%;

        @include breakpoint(tablet) {
          width: 100%;
        }
      }
    }

  }
}