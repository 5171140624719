
.search {
  position: absolute;
  bottom: 18vw;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  width: 39px;

  &.zoomedin {
    bottom: 40vw;
  }

  .cls-1{
    fill:#f16900;
  }
  .cls-2{
    fill:#fff;
  }

  img {
    cursor: pointer;
  }

  .search-popup {
    width: 100%;
    height: 100%;
    background: transparent;
    border: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events:none;

    &.active {
        opacity: 1;
        pointer-events: unset;
    }
  }

  .search-bg {
    background: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .search-content {
    flex: 1;
  }

  .search-box {
    width: calc(100% - 80px);
    background: #fff;
    border-radius: 10px;
    position: fixed;
    bottom: 20px;
    left: 0;
    padding: 20px;
    right: 0;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;

    input {
      border: 0;
      width: 100%;
      padding: 7px 0 7px 0;
      font-family: "Nitti";
      font-size: 20px;
      color: #555;
    }

    input:focus{
      outline: none;
    }
  }

  .search-icon {
    width: 40px;
    height: 40px;
  }

  .search-results {
    .result {
      font-family: "Nitti";
      font-size: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      span.no {
        color: #f16900;
        font-size: 16px;
        width: 25px;
        text-align: left;
      }
      span.name {
        color: #555;
        font-size: 18px;
      }
    }
  }
}
  