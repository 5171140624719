.intro {
  padding: 20px 5px;
  transition: all 0.5s ease;
  opacity: 1;
  flex: 1;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: all 0.5s ease;
  }

  .cls-1 {
    fill:#f16900;
  }

  svg {
    height: 145px;
    transition: all 0.5s ease;

    @include breakpoint(tablet) {
      height: 175px;
    }
  }
  .svg-logo-2 {
    transition: all 0.5s ease;
    opacity: 0;
  }

  h2 {
    color: #FE6300;
    font-size: 37px;
    line-height: 40px;
    margin: 0 0 0 0;
    font-family: "Nitti";
    font-weight: 400;
    transition: all 0.5s ease;

    @include breakpoint(tablet) {
      font-size: 70px;
      line-height: 72px;
      width: 570px;
    }
    
    span {
      transition: all 0.5s ease;
      opacity: 1;
    }
  }
  .intro-content {
    transition: all 0.5s ease;
    height: 230px;
    @include breakpoint(tablet) {
      height: 324px;
    }
  }

  &.zoomedOut {
    .intro-content {
      padding-bottom: 0;
      height: 125px;

      @include breakpoint(tablet) {
        height: 210px;
      }
    }

    svg {
      height: 40px;

      @include breakpoint(tablet) {
        height: 70px;
      }
    }
    .svg-logo-1 {
      
    }
    .svg-logo-2 {

      opacity: 1;

      @include breakpoint(tablet) {
        opacity: 1;
      }
    }

    h2 {
      @include breakpoint(tablet) {
        font-size: 64px;
        line-height: 66px;
        width: 570px;
        font-weight: 400;
      }

      span {
        @include breakpoint(tablet) {
          opacity: 0;
        }
      }
    }
  }

  &.fadeOut {
    opacity: 0;

    @include breakpoint(tablet) {
      opacity: 1;
    }
  }
}